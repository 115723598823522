import React from "react";
import { Image } from "./image";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Nossos Parceiros</h2>
          <p>
            Venha você também fazer parte dos parceiros GENTE DA GENTE, tirando as crianças e jovens do risco do crime, para um mundo de oportunidades!
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  {/*<i className={d.icon}></i>*/}
                  <Image
                      smallImage={d.smallImage}
                    />
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
