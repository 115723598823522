import React, { useState } from "react";
import emailjs from "emailjs-com";

const initialState = {
  name: "",
  email: "",
  message: "",
};

export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [successMessage, setSuccessMessage] = useState(""); // Estado para mensagem de sucesso

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);

    emailjs
      .sendForm("service_u67tcdk", "template_xi4fvlu", e.target, "uQ6gO7yaKiqfWeQTb")
      .then(
        (result) => {
          console.log(result.text);
          setSuccessMessage("Cadastro enviado com sucesso!"); // Exibir mensagem de sucesso
          clearState();
        },
        (error) => {
          console.log(error.text);
          setSuccessMessage("Ocorreu um erro ao enviar o cadastro. Tente novamente."); // Mensagem de erro
        }
      );
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Entre em contato</h2>
                <p>
                  Por favor, preencha o formulário abaixo para nos enviar um e-mail e entraremos em contato com você o mais breve possível.
                </p>
              </div>
              <form name="sentMessage" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Nome"
                        required
                        onChange={handleChange}
                        value={name} // Necessário para controlar o estado do input
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="E-mail"
                        required
                        onChange={handleChange}
                        value={email} // Necessário para controlar o estado do input
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="nome, idade, posição, e numero de contato do responsavel"
                    required
                    onChange={handleChange}
                    value={message} // Necessário para controlar o estado do input
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success">{successMessage && <p className="text-success">{successMessage}</p>}</div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Enviar Mensagem
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Informações de contato</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Endereço
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Telefone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              {/* <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p> */}
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"} aria-label="Facebook">
                      <i className="fa fa-whatsapp"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"} aria-label="Instagram">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    {/* <a href={props.data ? props.data.youtube : "/"} aria-label="YouTube">
                      <i className="fa fa-youtube"></i>
                    </a> */}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 GDG. Design by{" "}
            <a href="https://www.instagram.com/bioteckster/" aria-label="Design by Bioteckster">Click me</a>
          </p>
        </div>
      </div>
    </div>
  );
};
