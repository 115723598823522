import React from "react";

export const Features = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Programação</h2>
          <h1>⚽⚽⚽ATENÇÃO ESTES HORÁRIOS AOS SÁBADOS NÃO SERÃO MAIS ALTERADOS.
          ⚽⚽⚽</h1>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-xs-6 col-md-3">
                  {" "}
                  <i className={d.icon}></i>
                  <h2>{d.title}</h2>
                  <h3>{d.title1}</h3>
                  <p>{d.text}</p>
                  <p>{d.text1}</p>
                  <p>{d.text2}</p>
                  <p>{d.text3}</p>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
